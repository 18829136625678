.aboutus {
  background: url('../../img/content-box-bg.png') no-repeat center center #000;
  height: auto;
  flex-grow: 1;
  display: table;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-size: cover;
}
