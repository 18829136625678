html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Heebo';
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
