.read-more-less--more {
  color: grey;
}

.read-more-less--less {
  color: grey;
}

.landingpageclass .backgroundimage {
  background: url('../img/content-box-bg.png') no-repeat center center #000;
  height: 100%;
  display: table;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.grid-root-apple {
  margin-left: 10px;
}

.landingpageclass .backgroundimage {
  background: url('../img/content-box-bg.png') no-repeat center center #000;
  height: calc(100vh - 56px);
  display: table;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.landingpageclass .menu-btn:hover {
  background: red !important;
}

.landingpageclass .menu-btn {
  color: #bdbdbd !important;
}

.landingpageclass .menu-btn.active {
  color: #ffffff !important;
}

.landingpageclass .menu-btn.MuiButton-text {
  text-transform: capitalize;
  font-family: 'Lato' !important;
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
  border-radius: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
.landingpageclass .menu-btn.MuiButton-text {
  padding-left: auto !important;
  padding-right: auto !important;
  font-size: 13px;
}

.landingpageclass .MuiTypography-root {
  padding-left: 0%;
}

.landingpageclass .logo-style {
  margin-top: 10px;
}

.color-red {
  color: #f75a4a;
  margin-bottom: 15px;
}

.randomtext {
  margin-bottom: 34px;
}

.back-for-banner-color {
  background-color: black;
  height: 100%;
}

.back-color-for-works-section {
  background-color: #1f1f1f;
  height: 100%;
}

.back-color-for-community-videos {
  background-color: red;
  height: 100%;
}

.back-color-for-footer {
  background-color: #1f1f1f;
  height: 100%;
  padding: 10px;
}

.padding-for-work-section {
  padding-top: 140px;
  padding-bottom: 100px;
}

.padding-for-content {
  padding-top: 140px;
}

.padding-for-content-last-content {
  padding-top: 140px;
  padding-bottom: 100px;
}

.padding-for-content img {
  height: 467px !important;
}

.first-text-color {
  color: white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 49px;
  margin-top: 10px;
}

.second-text-color {
  color: white;
  font-family: 'Lato';
  font-style: normal;
  width: 100%;
  max-width: 396px;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-top: 27px;
}

.third-text-color {
  color: white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 49px;
  margin-top: 10px;
}

.fourth-text-color {
  color: white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  width: 100%;
  max-width: 396px;
  font-size: 18px;
  line-height: 32px;
  margin-top: 27px;
}

.first-grid-left {
  padding-left: 30px;
  padding-right: 30px;
}

.second-grid-left {
  padding-left: 30px;
  padding-right: 30px;
}

.third-grid-left {
  padding-left: 30px;
  padding-right: 30px;
}

.fourth-grid-left {
  padding-left: 30px;
  padding-right: 30px;
}

.how-it-work {
  color: white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
}

.Red-color-image img {
  width: 15px !important;
}

.Image-tag {
  text-align: initial;
  margin-top: 20px;
}

.learn-more-tag {
  text-align: end;
}

.community {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: white;
  margin-top: 42px;
}

.private-videos {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  color: white;
  margin-top: 0px;
}

.at-verso-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: white;
  margin-bottom: 36px;
  margin-top: 13px;
}

.get-the-app {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: white;
  margin-bottom: 5px;
}

.grid-design-for-image {
  padding-left: 140px;
}

.First-image img {
  width: 239px !important;
}

.footer-logo-style img {
  width: 50px !important;
  height: 50px !important;
  padding-top: 10px !important;
}

.differnet-css img {
  width: 228px !important;
  padding-top: 80px !important;
}

.copy-right-text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: white;
  padding-top: 10px;
}

.footer-logo-images {
  text-align: end;
}

.footer-logo-images img {
  width: 38px !important;
  padding: 10px 6px 4px 9px !important;
}

.differnet-css {
  margin-bottom: -6px !important;
  text-align: end;
  margin-top: 18px !important;
}

.program-wrapper-mobile {
  display: none;
}

.social-media {
  display: flex;
  margin-top: 20px;
}

.google-play img {
  height: 50px !important;
}

.app-store {
  padding-left: 18px;
}

.app-store img {
  height: 50px !important;
}

@media (max-width: 1325px) {
  .cart-icon-changes {
    margin-right: 0%;
  }
}
@media (max-width: 1097px) {
  .landingpageclass .menu-btn.MuiButton-text {
    padding-left: 16px;
    padding-right: 0px;
    font-size: 13px;
  }
}

@media (max-width: 1024px) {
  .landingpageclass .css-gtoczj {
    padding-left: 10%;
  }

  .grid-design-for-image {
    padding-left: 1%;
    padding-right: 20px;
  }

  .banner-photo {
    height: 450px;
    width: 500px;
    padding-right: 15px;
  }
}
@media (max-width: 1200px) {
  .landingpageclass .css-gtoczj {
    padding-left: 10%;
  }

  .grid-design-for-image {
    padding-left: 1%;
    padding-right: 20px;
  }

  .banner-photo {
    height: 540px;
    width: 500px;
    padding-right: 15px;
  }
}

@media (max-width: 900px) {
  .landingpageclass .css-gtoczj {
    padding-left: 10%;
  }

  .grid-design-for-image {
    padding-left: 1%;
    padding-right: 20px;
  }

  .banner-photo {
    height: 450px;
    width: 500px;
    padding-right: 15px;
  }
}

@media (max-width: 995px) {
  .landingpageclass .menu-btn.MuiButton-text {
    padding-left: 11px;
    padding-right: 21px;
  }
}
@media (max-width: 899px) {
  .banner-photo {
    max-width: 100% !important;
    text-align: center;
    padding-right: 0px !important;
  }

  .banner-photo img {
    width: 370px !important;
  }

  .footer-logo-images {
    text-align: center;
  }

  .footer-logo-style {
    text-align: center;
  }

  .back-color-for-footer {
    padding: 0;
  }

  .landingpageclass .css-gtoczj {
    padding-left: 2%;
  }

  .grid-design-for-image {
    padding-left: 0;
  }

  .first-text-color {
    font-size: 29px;
    line-height: 46px;
  }

  .second-text-color {
    max-width: 350px;
    font-weight: 350;
    font-size: 16px;
    line-height: 26px;
    margin-top: 16px;
  }

  .third-text-color {
    font-size: 29px;
    line-height: 46px;
  }

  .fourth-text-color {
    max-width: 350px;
    font-weight: 350;
    font-size: 16px;
    line-height: 26px;
    margin-top: 16px;
  }

  .padding-for-content {
    padding-top: 80px;
  }

  .padding-for-content .first-grid-left img {
    height: 400px !important;
  }

  .padding-for-content .fourth-grid-left img {
    height: 400px !important;
  }

  .padding-for-content-last-content {
    padding-bottom: 20px !important;
  }

  .padding-for-content-last-content .program-wrapper-mobile {
    padding-bottom: 20px;
  }

  .community {
    font-size: 26px;
    line-height: 40px;
  }

  .private-videos {
    font-size: 26px;
    line-height: 40px;
  }

  .at-verso-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: white;
    margin-bottom: 32px;
    margin-top: 8px;
  }

  .get-the-app {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: white;
    margin-bottom: 3px;
  }
}

@media (max-width: 599px) {
  .banner-photo {
    padding-left: 20px;
    padding-right: 20px !important;
  }
}
@media (max-width: 425px) {
  .app-store img,
  .google-play img {
    height: auto !important;
  }
  /* .padding-for-content .first-grid-left img,
  .padding-for-content .fourth-grid-left img {
    height: auto !important;
    width: auto !important;
  } */
  .how-it-works {
    padding-top: 50px;
  }

  .program-wrapper {
    display: none;
  }

  .program-wrapper-mobile {
    display: block;
  }

  .second-text-color {
    text-align: center;
  }

  .fourth-text-color {
    text-align: center;
  }

  .grid-design-for-image {
    padding-left: 0;
  }

  .first-text-color {
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    margin-top: 50px;
  }

  .second-text-color {
    color: white;
    font-family: 'Lato';
    font-style: normal;
    width: 100%;
    max-width: 396px;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-top: 20px;
  }

  .third-text-color {
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    margin-top: 50px;
  }

  .fourth-text-color {
    color: white;
    font-family: 'Lato';
    font-style: normal;
    width: 100%;
    max-width: 396px;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-top: 20px;
  }

  .padding-for-content {
    padding-top: 30px;
  }

  .padding-for-content-last-content {
    padding-top: 30px;
  }

  .social-media {
    justify-content: center;
  }

  .community {
    font-size: 28px;
    line-height: 34px;
  }

  .private-videos {
    font-size: 28px;
    line-height: 34px;
  }
}
