.slick-dots li.slick-active button:before {
  color: white !important;
}
#responsive-dialog-title {
  position: relative !important;
}
.back-btn {
  padding: 0px 10px 0px 0px !important;
  min-width: auto !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  span {
    margin-left: 15px;
  }
}
.checkout-dialog-title {
  position: absolute !important;
  margin-left: 15px !important;
  left: 25% !important;
}

.slick-dots li button:before {
  font-size: 13px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 27px !important;
}

.slick-list {
  text-align: center !important;
}

.Descriptions-text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: white;
  margin-bottom: 12px;
}

.full-access-text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  width: 90%;
  color: #b9b9b9;
}

.full-community-acess {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffd600;
  margin-top: 25px;
  margin-bottom: 16px;
}

.loreum-ipsum-task {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #b9b9b9;
}

.purchase-table-changes .MuiDataGrid-overlay {
  height: auto;
  top: 54px;
  background-color: transparent;
  width: 1253px;
  font-size: 14px;
  z-index: 4;
  pointer-events: none;
  position: absolute;
  color: white;
}

.new-button-design {
  margin-left: -4px;
  margin-top: 7px;
  border-radius: 4px;
  height: 24px;
  font-family: 'Lato';
  font-style: normal;
  font-size: 16px;
  padding: 13px 9px 15px 10px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: bold;
}

.rating-section {
  display: flex;
}

.sale-button-design {
  margin-left: -4px;
  margin-top: 7px;
  border-radius: 4px;
  height: 24px;
  font-family: 'Lato';
  font-style: normal;
  font-size: 16px;
  padding: 13px 9px 15px 10px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: bold;
}

.for-Dropdownmenu .MuiPaper-root {
  width: 10% !important;
  float: right !important;
  position: relative !important;
  top: 65px !important;
  right: 404px !important;
  left: auto !important;
}

.sales-class {
  margin-top: -9px;
  margin-right: -11px;
  border-radius: 100%;
  padding: 8px;
  min-height: 3.236em;
  min-height: 3.236em;
  background-color: #77a464;
  min-width: 3.236em;
  line-height: 3;
  color: white;
}

.banner-title-app {
  font-size: 51px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.MuiDrawer-paper {
  background-color: black;
}
.portal-wrapper {
  .content-box-wrap {
    background: url('../img/content-box-bg.png') no-repeat center center #000;
    height: 100%;
    display: table;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-size: cover;
  }

  .auth-box {
    background-color: #000;
    height: 100%;
    display: table;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.personal-info-wrapper.records-metrics {
  .auth-btn {
    display: flex;

    button {
      height: auto !important;
      padding: 0 20px !important;
    }

    .prev-btn {
      text-align: left;
    }

    .next-btn {
      text-align: right;
    }
  }

  .form-group {
    margin-bottom: 0 !important;
  }

  .radio {
    padding: 0 20px;
  }
}

.personal-info-wrapper {
  .gender-radio {
    display: table;

    .gender-radio-label {
      color: #ffffff !important;
      justify-content: left !important;
      text-align: left !important;
      padding: 0 20px;
      display: block;
      margin-top: 9px;
    }

    .gender-form {
      display: table-cell;
      padding: 0 20px;

      .radio-box {
        display: contents;
      }

      .custom-radio {
        background: #1d1d1d;
        border-radius: 0px 6px 6px 0px;
        height: 45px;
        width: 90px;
        justify-content: center;
        margin: 0;
      }

      .MuiButtonBase-root {
        display: none !important;
      }

      .custom-radio.active {
        background: #ed1f24 !important;
        box-shadow: 0px 6px 6px rgba(80, 107, 255, 0.06) !important;
        border-radius: 6px 0px 0px 6px !important;
        height: 45px;
        width: 90px;
        justify-content: center;
        margin: 0;
      }
    }
  }

  .form-group-label {
    margin-top: 26px !important;
    margin-bottom: 1px !important;
  }

  .title-label {
    color: #ffffff !important;
    padding: 0 20px;
  }

  .profile-photo-wrapper {
    padding: 0 20px;
    display: flex;

    .upload-photo {
      background: #1d1d1d;
      border: 1px solid #585656;
      border-radius: 6px;
      height: 42px;
      color: #ffffff;
      text-transform: capitalize;
    }

    .remove-button {
      margin-left: 17px;
    }

    .profile-img {
      margin-right: 25px;
    }

    .upload-photo-wrapper {
      margin-top: 15px;
    }

    button {
      text-transform: capitalize;
    }
  }
}

.search-bar {
  color: #ffffff;
}

.filter-programs {
  margin-left: 10%;

  .filter-text {
    padding-left: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  .MuiFormControl-root {
    background: #0c0c0c !important;
    border: 1px solid #403f3f !important;
    border-radius: 5px !important;
  }

  .MuiSvgIcon-root {
    display: none;
  }
}

.personal-info-wrapper-Section1.records-metrics {
  .button-for-back button {
    border-radius: 80px;
    width: 91%;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    height: 60px;
  }

  .form-group-label {
    margin-top: 26px !important;
    margin-bottom: 1px !important;
  }

  .title-label {
    color: #ffffff !important;
    padding: 0 20px;
  }
}

.programs-wrapper {
  background: #1d1d1d;
  padding-bottom: 30px;

  .search-bar {
    text-align: right;
    margin-right: 10%;
  }

  .search-bar .MuiOutlinedInput-root {
    padding-right: 0px !important;
    background: #272626;
    border-radius: 5px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #979797;
  }

  .my-programs-tab {
    .MuiTabs-root {
      padding-left: 5% !important;
    }

    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #575555;
    }
  }

  .MuiTabs-flexContainer {
    .tab {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 19px !important;
      text-align: center !important;
      color: #575555;
    }

    .Mui-selected {
      color: #ffffff !important;
    }
  }

  .filer-text {
    color: white !important;
  }

  .MuiInputBase-input-MuiOutlinedInput-input {
    background-color: white !important;
    border-right: 2px solid black !important;
  }

  .page-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;
    color: #ffffff;
    width: 100% !important;
    max-width: 100% !important;
  }

  .profile-image {
    margin: auto;
    display: block;
    margin-top: 10px;
    text-align: center;
  }

  .vector-property {
    height: 34px;
    right: 0;
    position: absolute;
    left: 137px;
  }

  .position-relative {
    position: relative;
  }

  .john-smith {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    margin-top: 10px;
    color: white;
  }

  .Male {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: #ed1f24;
    text-transform: capitalize;
  }

  .plan-descriptio {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    color: #ffffff;
    width: 100% !important;
    max-width: 100% !important;
  }

  .yearly-become {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 49px;
    color: #ffffff;
  }

  .yearly-message {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 47px;
    text-align: center;
    margin-top: 30px;
    color: #ffffff;
  }

  .current-plan-message {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 30px;
    color: #ffffff;
  }

  .Become-premium-member {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    margin-left: 23px;
    margin-top: 7px;
    font-size: 20px;
    line-height: 24px;
  }

  .member-user {
    display: flex;
  }

  .member-user-premium-menber {
    display: flex;
    justify-content: right;
  }

  .member-user-yearly-text-message {
    display: flex;
    justify-content: space-between;
  }

  .current-plan-status {
    display: flex;
    justify-content: right;
  }

  .description-for-plans {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    margin-top: 31px;
    font-size: 20px;
    line-height: 32px;
    color: #b9b9b9;
  }

  .add-cart-btn-wrapper {
    width: 48%;
    text-align: right;
  }

  .add-card-btn {
    padding: 10px 18px 10px 18px;
    background: #ed1f24;
    border-radius: 88.4px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
  }

  .Subscribe-button {
    margin: auto;
    display: block;
    padding: 11px 10px 10px 10px;
    background: #ed1f24;
    border-radius: 88.4px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    height: 52px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    width: 230px;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
  }

  .Subscribe-button-subscribe-now {
    margin: auto;
    margin-top: 18px;
    display: block;
    padding: 11px 48px 11px 48px;
    background: #ed1f24;
    border-radius: 88.4px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    height: 52px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
  }

  .weight {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }

  .kg-weight {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
    color: #ffffff;
    font-size: 27px;
    line-height: 24px;
    margin-top: 27px;
    text-transform: capitalize;
  }

  .profile-weight {
    background: #1a1818;
    border: 1px solid #494545;
    border-radius: 10px;
    position: absolute;
    width: 15%;
    height: 227px;
    padding: 34px 38px 10px 38px;

    img {
      margin: auto;
      display: block;
    }
  }

  .distrubeted-access-section {
    display: flex;
    justify-content: space-between;
  }

  .text-message-for-commuinty-section {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
    color: #ffffff;
  }

  .premium-benefits-section-for-profile {
    height: 88px;
    background: #1a1818;
    border-radius: 10px;
    padding: 30px;
  }

  .save-card-wrapper {
    padding-left: 10px;
    margin-top: 12px;
    margin-bottom: 12px;

    .add-card-btn.cancel {
      padding: 10px;
      background: #3e3e3f;
      margin-left: 10px;
    }

    .add-card-btn.save {
      padding: 10px;
    }
    .add-card-btn.save.checkout-guest {
      padding: 14px 30px;
    }
  }

  .checkout-btn-cart {
    height: 40px;
    background: #ed1f24;
    border-radius: 88.4px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    flex: auto;
    text-decoration: none;
    padding-left: 1%;
    padding-right: 1%;
  }

  .checkout-btn {
    background: #ed1f24;
    border-radius: 88.4px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    flex: auto;
    text-decoration: none;
    padding-left: 1%;
    padding-right: 1%;
  }

  .checkout-wrapper {
    width: 100%;
    text-align: right;
  }

  .divider {
    background: red;
    border: 4px solid transparent;
    width: 102px;
    margin-top: 11px;
  }

  .program-image-wrapper {
    position: relative;
    width: 100%;
  }

  .program-image-secondone {
    margin-top: 18px;
    min-height: 300px;
    margin: 10px 10px 10px 0px;
    padding: 10px 10px 10px 10px;

    span {
      width: 85% !important;
    }

    img {
      border-radius: 10px;
    }
  }

  .program-contentsecond {
    text-align: left;
    bottom: 0;
    width: 92%;
    color: white;
    font-size: 17px;
    padding: 10px 0px 10px 35px;
  }

  .program-image {
    width: 100%;
    min-height: 300px;

    span {
      width: 100% !important;
    }

    img {
      width: 100%;
      border-radius: 10px;
    }
  }

  .program-content {
    bottom: 0;
    padding: 5% 5% 0 5%;
    width: 100%;
  }
  .program-title {
    min-height: 58px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .program-content.bottom {
    margin-top: auto;
    padding-top: 0;
  }
  .program-image-wrapper {
    display: flex;
    flex-direction: column;
  }

  .program-title,
  .program-description {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 14px;
    line-height: 29px;
    color: #ffffff;
  }

  .program-duration {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 14px;
    color: red;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .price-text-value {
    color: red;
    font-size: 18px;
  }

  .rating-wrapper {
    display: block;

    .price {
      font-family: 'Lato' !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 20px !important;
      line-height: 24px !important;
      color: #ed1f24 !important;
      width: 55%;
    }

    .program-rating {
      display: flex;
      margin-bottom: 12px;
      justify-content: space-between;

      .MuiRating-iconFilled {
        color: #ffffff;
      }

      .MuiRating-iconEmpty {
        svg {
          color: #ffffff;
        }
      }

      div.MuiBox-root {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        margin-left: 4px;
        color: #ffffff;
      }
    }
  }
  .tags-wrapper > div {
    float: left;
    width: auto;
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .program-tags {
    display: flex;
    margin-bottom: 10px;

    .tag {
      padding-right: 5px;
      margin-bottom: 0px;
      margin-top: 6px;

      span {
        background: #696969;
        border-radius: 86px;
        color: #ffffff;
        padding: 10px 23px 10px 23px;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        display: block;
        line-height: 3px;
        width: 100% !important;
        color: #ffffff;
      }
    }
  }

  .program-actions {
    text-align: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;

    .cart-text {
      text-transform: none;
      font-size: 12px;
    }

    .read-more-text {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      font-size: 19px;
      float: right;
      text-decoration-color: #ed1f24;
    }

    .actual-price {
      text-decoration: line-through;
      padding-left: 5px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: rgba(255, 255, 255, 0.6);
    }

    .cart-divider {
      background: #ffffff;
      opacity: 0.3;
      height: 16px;
      width: 2px;
      margin-left: 5px;
    }

    .cart-icon {
      padding-left: 5px;

      span {
        top: 4px;
      }
    }
  }

  .border-for-plans {
    // width: 90%;
    padding: 35px;
    background: #383636;
    border-radius: 20px;
  }

  .silver {
    text-align: center;
    text-transform: uppercase;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 48px;
    color: #ffffff;
  }

  .amount {
    font-family: 'Lato';
    text-align: center;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;
    margin-top: 30px;
    color: #ed1f24;
  }

  .yearly {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }

  .premiun-benifts-words {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffd600;
  }

  .premium-benefits {
    text-align: center;
    text-transform: uppercase;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-top: 29px;
    margin-bottom: 32px;
    padding: 20px;
    color: #ffd600;
  }

  .commuity-access {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    margin-left: 11px;
  }

  .main-class-for-tick {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .become-premium-member {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffd600;
    margin-top: 71px;
    margin-bottom: 18px;
  }

  .profile-section-user {
    background: #1a1818;
    border: 1px solid #494545;
    padding: 16px;
    margin-top: 65px;
    margin-bottom: 31px;
    border-radius: 10px;
    width: 100%;
  }

  .profile-section-user-next {
    background: #3f3919;
    border: 1px solid #ffd600;

    padding: 16px;
    margin-top: 65px;
    margin-bottom: 31px;
    border-radius: 10px;
    width: 100%;
  }
}

.purchase-table-changes,
.displaying-purpose {
  display: flex;
}

.purchase-table-changes,
.MuiDataGrid-columnHeaderTitleContainer {
  flex-direction: row !important;
}

// {
//
// }
.paypal {
  &-logo {
    font-family: Verdana, Tahoma;
    font-weight: bold;
    font-size: 26px;

    i:first-child {
      color: #253b80;
    }

    i:last-child {
      color: #179bd7;
    }
  }

  &-button {
    padding: 15px 30px;
    border: 1px solid #ff9933;
    border-radius: 5px;
    background-image: linear-gradient(#fff0a8, #f9b421);
    margin: 0 auto;
    display: block;
    min-width: 138px;
    position: relative;

    &-title {
      font-size: 14px;
      color: #505050;
      vertical-align: baseline;
      text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
    }

    .paypal-logo {
      display: inline-block;
      text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
      font-size: 20px;
    }
  }
}
.purchase-table-changes .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover,
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected.Mui-hovered {
  background-color: transparent;
}

.purchase-table-changes
  .MuiDataGrid-root
  .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none;
}

.purchase-table-changes .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none;
}

.purchase-table-changes .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: transparent;
}

.purchase-table-changes .MuiDataGrid-root {
  border: 2px solid transparent;
}

.purchase-table-changes .MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none;
}

.purchase-table-changes .MuiPaper-root {
  width: 90%;
}

.purchase-table-changes .MuiDataGrid-root .MuiDataGrid-sortIcon,
.MuiDataGrid-root .MuiDataGrid-filterIcon {
  color: red;
}

.purchase-table-changes
  .MuiDataGrid-root
  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover
  .MuiDataGrid-sortIcon {
  opacity: 2;
}

.purchase-table-changes
  .MuiDataGrid-root
  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted)
  .MuiDataGrid-sortIcon {
  opacity: 2;
}

.MuiDataGrid-iconButtonContainer {
  visibility: initial;
}

.purchase-table-changes .MuiPaper-root {
  background-color: transparent;
}

.purchase-table-changes .MuiDataGrid-root .MuiDataGrid-cell--textRight {
  justify-content: center !important;
}

.purchase-table-changes .MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: 0.5px solid grey;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}
.special-offer {
  background: #ed1f24;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Lato' !important;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  .description {
    margin-right: 10px;
  }
  .timer {
    padding: 2px;
    text-align: center;
    justify-content: center;
    margin: 5px;
    height: 50px;
    width: 50px;
    background: #ffffff;
    color: black;
    border: 1px solid white;
    border-radius: 5px;
  }
}

.purchase-table-changes .table-style,
.MuiDataGrid-columnHeaderTitleContainer {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  // padding: 21px 23px 18px 18px;
  color: #a6a6a6;
}

.purchase-table-changes .item-image img {
  border-radius: 10px;
  margin-right: 10px;
}

.purchase-table-changes .item-details {
  margin-top: 40px;
  margin-left: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: white;
}

.purchase-table-changes .history-status {
  width: 73px;
  height: 30px;
  background: #105033;
  border: 1px solid #00f076;
  border-radius: 30px;
}

.purchase-table-changes .paid {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 5px;
  color: #15cf74;
}

.purchase-table-changes .history-pending {
  background: #621212;
  border: 1px solid #ed1f24;
  border-radius: 30px;
  height: 30px;
}

.purchase-table-changes .pending {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ff5c60;
  margin-top: 5px;
  text-align: center;
}

.purchase-table-changes .table-style-for-text,
.MuiDataGrid-cell {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  padding: 1px 23px 23px 18px;
  color: white;
}
.bundle-programs-wrapper {
  margin-top: 50px;

  .title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 24px;
    line-height: 32px;
    color: #ffffff;
  }
  .item-wrapper {
    background: #252525;
    border-radius: 10px;
    display: flex;
    padding: 10px;
    .item-title {
      font-family: 'Lato';
      font-style: normal;
      margin-bottom: 10px;
      margin-left: 14px;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
      max-width: 294px;
      padding-top: 10px;
    }
    .item-price {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #ed1f24;
      margin-left: 14px;
      padding-top: 10px;
    }
  }
}
.program-details-wrapper {
  padding: 6% 8% 0% 8% !important;

  .border-for-google-app-playstore {
    width: 70%;
    margin-top: 21px;
    padding: 20px 10px 11px 18px;
    background: #3f3919;
    border: 1px solid #ffd600;
    border-radius: 16px;
  }

  .start-training-value {
    margin-bottom: 31px;
    color: #ffffff;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .program-actions {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    margin-top: 18px;
    color: #ffffff;

    .MuiButton-root {
      width: 182px;
      height: 51px;
    }

    .cart-text {
      text-transform: none;
      font-size: 17px;
    }

    .actual-price {
      text-decoration: line-through;
      padding-left: 5px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: rgba(255, 255, 255, 0.6);
    }

    .cart-divider {
      background: #ffffff;
      opacity: 0.3;
      height: 35px;
      width: 2px;
      margin-left: 20px;
    }

    .cart-icon {
      width: 22%;
      text-align: end;

      span {
        top: 4px;
      }
    }
  }

  .program-actions-details {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    float: right;
    color: #ffffff;

    .MuiButton-root {
      width: 130px;
      height: 51px;
    }

    .cart-text {
      text-transform: none;
      font-size: 17px;
    }

    .cart-icon {
      width: 22%;
      text-align: end;

      span {
        top: 4px;
      }
    }
  }

  .program-title {
    font-family: 'Lato';
    font-style: normal;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #ffffff;
  }

  .program-details-image {
    margin-right: 66px;
  }

  .program-details-image span {
    width: 100% !important;
  }

  .program-details-image img {
    width: 100% !important;
  }

  .program-rating {
    align-items: center;
    display: flex;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;

    color: #ffffff;

    .MuiRating-iconFilled {
      color: #ffffff;
    }

    .star-margin .MuiRating-decimal {
      margin-right: 4px;
    }

    .review-start .MuiRating-decimal {
      margin-right: 0px;
    }

    .MuiRating-iconEmpty {
      svg {
        color: #ffffff;
      }
    }
  }

  .program-tags {
    display: flex;
    margin-top: 12px;
    margin-bottom: 7px;
    flex-wrap: wrap;
    .tag {
      padding-right: 5px;

      span {
        background: #696969;
        border-radius: 86px;
        color: #ffffff;
        padding: 10px;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #ffffff;
      }
    }
  }

  .price {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 2px;
    line-height: 38px;
    color: #ed1f24;
  }

  .program-extra-details {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 44px;
    color: #696969;

    span {
      color: #ffffff;
    }

    p {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  }

  .program-description-wrapper {
    margin-top: 68px;

    .title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 24px;
      line-height: 32px;
      color: #ffffff;
    }

    .desc {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #b9b9b9;
    }
  }

  .section-for-showing-review {
    margin-bottom: 13px;
    padding: 25px;
    background: #1d1d1d;
    border: 1px solid #4a4f59;
    border-radius: 10px;
  }

  .section-for-showing-comments {
    margin-bottom: 13px;
    padding: 10px 17px 10px 17px;
    background: #1d1d1d;
    border: 1px solid #4a4f59;
    margin-top: 48px;
    border-radius: 10px;
  }

  .review-text {
    color: white;
    font-size: 20px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }

  .view-all-text {
    text-align: end;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ed1f24;
  }
}

.navbar-action-buttons {
  &:hover {
    background-color: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiBadge-root.BaseBadge-root,
.MuiBadge-root.MuiBadge-root {
  span {
    height: 40px !important;
    width: 40px !important;
  }

  span.MuiBadge-overlapCircular {
    height: 20px !important;
    width: 20px !important;
    background-color: #ed1f24 !important;
    color: #ffffff;
  }

  span.MuiBox-root {
    height: auto !important;
    width: auto !important;
  }
}
.stripe-elements {
  padding-left: 13px;
}
#demo-select-small {
  color: #ffffff;
  text-align: left;
}
.payment-summary-wrapper {
  margin-left: 10%;
  margin-bottom: 13px;
  padding: 20px;
  background: #1d1d1d;
  border: 1px solid #4a4f59;
  border-radius: 10px;
  height: 630px;

  .title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 48px;
    text-transform: none;
    color: #ffffff;
    padding-left: 10px;
  }

  .promo-code-wrapper {
    display: flex;
    // padding-left: 10px;
    padding-top: 20px;
    // justify-content: center;
    align-items: center;

    .promo-action {
      padding-left: 10px;
    }
  }

  .promo-input-box {
    background: #252525;
    border-radius: 10px;
    width: 100%;
    // label {
    //   // color: #555d71 !important;
    // }

    // .MuiFormControl-root {
    //   width: 400px;
    // }
  }

  .promo-action {
    button {
      color: #ffffff;
      font-size: 16px;
      font-family: 'Lato';
      font-style: normal;
      padding: 10px 10px 10px 10px 10px;
      padding: 10px 24px 10px 23px;
    }

    flex: auto;
    text-align: right;
    padding-right: 10px;
  }

  .billing-summary-wrapper {
    padding-left: 10px;
    padding-right: 10px;

    .summary-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      margin-top: 30px;
      color: #ffffff;
    }

    .summary-title-billing {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 19px;
      margin-top: 18px;
      color: #ffffff;
    }

    .summary-table {
      padding-top: 20px;

      .MuiPaper-root.MuiPaper-elevation {
        background: none !important;
        color: #1d1d1d !important;
      }

      .table-divider {
        background: #4d3e3e;
        border: 2px solid;
      }
    }

    .summary-table.total {
      padding-top: 25%;
    }

    .summary-table.card-checkout {
      padding-top: 50%;
    }

    .left-text {
      text-align: left !important;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 50px;
      color: #979797;
      padding: 0 !important;
      border-bottom: 0 !important;
    }

    .right-text {
      text-align: right !important;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 50px;
      text-align: right;
      color: #ffffff;
      padding: 0 !important;
      border-bottom: 0 !important;
    }

    .left-text.total {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
    }
  }
}

.form-wrap form.guest-checkout {
  padding: 15px 65px;
}
.shared-footer {
  color: #ffffff;
}
.radio-title {
  color: #ffffff;
  font-size: 18px;
  margin-top: 20px;
}
.order-title {
  &.billing {
    font-size: 36px;
  }
  margin: 30px 0 22px;
  color: #ffffff;
  font-size: 30px;
}
.promo-discount-description {
  width: fit-content;
  margin-top: 10px;
}
.cart-items-wrapper {
  .cart-items-count {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    margin-bottom: 19px;
    line-height: 48px;
    text-transform: capitalize;
    color: #ffffff;
  }

  .cart-items-list {
    max-height: 580px;
    overflow: auto;

    .review-item {
      display: flex;
      padding: 12px 10px 12px 10px;
    }

    .user-name-for-review {
      display: flex;
      justify-content: space-between;
    }

    .border-review-space {
      height: 0px;

      margin-top: 9px;
      border: 1px solid #4e4e4e;
    }

    .no-item {
      color: #ffffff;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
    }

    .add-card {
      padding-bottom: 16px;
    }

    .card-expire-wrapper {
      display: flex;

      .payment-field.expiry {
        width: 80% !important;
      }

      .payment-field.cvv {
        width: 80% !important;
      }
    }

    .item-wrapper {
      background: #252525;
      border-radius: 10px;
      display: flex;
      padding: 10px;
    }

    .item-radio {
      padding-top: 5px;

      span.MuiRadio-root {
        color: #404142;
      }

      span.MuiRadio-root.Mui-checked {
        color: #ed1f24;
      }
    }

    .item-image {
      img {
        padding: 15px !important;
        border-radius: 10px !important;
      }
    }

    .item-image-review {
      img {
        padding: 10px 10px 10px 0px !important;
        border-radius: 10px !important;
      }
    }
    .item-price {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #ed1f24;
      margin-left: 14px;
      padding-top: 10px;
    }
    .item-details {
      justify-content: center;
      width: 100%;

      .card-number {
        width: 100%;
        border-radius: 10px;
        background: #3e3e3f;
        padding-left: 10px;
      }

      .card-number.verify {
        width: 40%;
        border-radius: 10px;
        background: #3e3e3f;
        padding-left: 10px;
      }

      .card-name {
        .MuiOutlinedInput-notchedOutline {
          border: none !important;
        }

        width: 100%;
        padding: 10px;

        .MuiOutlinedInput-root {
          background: #3e3e3f;
          border-radius: 10px;
          color: #ffffff;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 48px;
          height: 50px;

          &:hover {
            border-color: #3e3e3f;
          }
        }
        input {
          &::placeholder {
            opacity: 0.2;
          }
        }
      }

      .position-relative,
      .card-expire-wrapper {
        padding: 10px 10px 2px 11px;
        align-items: center;
        width: 100% !important;
      }

      .payment-field.cvv {
        padding-left: 10px;
      }

      .payment-field {
        .position-relative {
          padding: 10px;
          align-items: center;
          width: 100% !important;
          padding-right: 0;

          input {
            padding-left: 10px !important;
          }
        }
      }

      .InputElement {
        padding-left: 10px !important;
      }

      .item-title {
        font-family: 'Lato';
        font-style: normal;
        margin-bottom: 10px;
        margin-left: 14px;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
        max-width: 294px;
        padding-top: 10px;
      }

      .item-title-review-name {
        font-family: 'Lato';
        font-style: normal;
        margin-left: 10px;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
        padding-top: 13px;
      }

      .item-title-add-reviews {
        font-family: 'Lato';
        font-style: normal;

        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
        padding-top: 30px;
        margin-bottom: 45px;
      }

      .text-field-multine .MuiFormControl-root {
        width: 100%;
        margin-top: 50px;
      }

      .text-field-multine .MuiOutlinedInput-input {
        color: #979797 !important;
      }

      .text-field-multine .MuiOutlinedInput-notchedOutline {
        border-color: #4a4f59 !important;
        border: 1px solid #585656;
        border-radius: 10px;
      }

      .add-comments-button {
        margin-top: 30px;
      }

      .cvc-element {
        margin-left: 14px;
      }

      .item-price {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #ed1f24;
        margin-left: 14px;
        padding-top: 10px;
      }

      .item-review-message-user {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: white;
        margin-left: 10px;
        padding-top: 5px;
        padding-bottom: 10px;
      }

      .item-verified-owner {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #979797;
        margin-left: 10px;
        padding-top: 10px;
        padding-bottom: 13px;
      }

      .review-date-display {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #979797;
        text-align: right;
        margin-left: 10px;
        padding-top: 10px;
        padding-bottom: 13px;
      }
    }

    .item-remove {
      text-align: right;
      flex: auto;
    }

    .button-remove-item {
      text-align: right;
      display: block;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  padding-left: 1%;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  padding-left: 1%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

.MuiButton-root.Mui-disabled {
  background: #616161;
  color: rgba(255, 255, 255, 0.4);
}

.payment-status-wrapper {
  padding-bottom: 100px;
  min-height: 80vh;

  .status-icon {
    margin-top: 146px;
  }

  .title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 50px;
    text-align: center;
    font-feature-settings: 'liga' off;
    color: #ffffff;
    margin-top: 20px;
  }

  .description {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
  }

  .actions {
    padding-top: 40px;

    a {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 26px;
      text-align: center;
      text-decoration-line: underline;
      font-feature-settings: 'liga' off;
      color: #ffffff;
    }
  }
}

.navbar-active {
  // background: url("../public/img/content-box-bg.png") no-repeat center center
  //   #000;
  position: static;
  // z-index: 2;
  top: 0;
  width: 100%;
  background-size: cover;
}
@media (min-width: 1441px) {
  .programs-wrapper .program-image {
    height: 384px;
    img {
      height: 384px;
    }
  }
  .summary-table.total {
    padding-top: 17% !important;
  }
  .form-wrap {
    &.checkout-guest {
      // width: 80% !important;
    }
  }
}
@media only screen and (min-width: 1269px) and (max-width: 1440px) {
  .programs-wrapper .program-image {
    height: 260px;
    img {
      height: 260px;
    }
  }
  .form-wrap {
    &.checkout-guest {
      // width: 80% !important;
    }
  }
}
@media (max-width: 1268px) {
  .programs-wrapper .program-image {
    height: 217px;
    img {
      height: 217px;
    }
  }

  .banner-title-app {
    font-size: 43px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .programs-wrapper .program-image {
    min-height: 200px;
  }
  .programs-wrapper .program-image-secondone {
    min-height: auto;
  }
}

@media (max-width: 1024px) {
  .programs-wrapper .program-tags .tag span {
    padding: 10px 8px 11px 9px;
    font-size: 10px;
  }
  .programs-wrapper {
    .program-title {
      font-size: 16px !important;
      line-height: normal;
    }
    .price-text-value {
      font-size: 16px;
    }
    .program-actions {
      .cart-text {
        font-size: 10px;
      }
      .cart-icon img {
        max-height: 15px !important;
        max-width: 20px !important;
      }
    }
  }

  .form-wrap {
    &.checkout-guest {
      width: 80% !important;
    }
    .item-wrapper {
      &.guest-checkout {
        // flex-wrap: wrap !important;
        // display: unset !important;
        // justify-content: unset !important;
      }
      .radio-title {
        font-size: 14px;
      }
    }
  }
  .for-Dropdownmenu .MuiPaper-root {
    width: 11% !important;

    top: 57px !important;
    right: 287px !important;
  }

  .banner-title-app {
    font-size: 35px;
    margin-bottom: 3px;
    margin-top: 12px;
  }
  .programs-wrapper .program-image {
    height: 217px;
    img {
      height: 217px;
    }
  }
  .programs-wrapper {
    height: auto;
    .profile-weight {
      height: 210px;
      padding: 20px 28px 10px 20px;
    }
    .kg-weight {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }
  }

  .programs-wrapper .member-user-premium-menber {
    justify-content: center;
  }

  .programs-wrapper .current-plan-status {
    justify-content: center;
  }

  .programs-wrapper .member-user {
    justify-content: center;
  }

  .member-user-premium-menber {
    justify-content: center;
  }

  .programs-wrapper .john-smith {
    margin-top: 15px;
    text-align: center;
  }

  .programs-wrapper .Male {
    text-align: center;
    margin-bottom: 10px;
  }

  .programs-wrapper .Subscribe-button {
    font-size: 16px;
    width: 100%;
    height: 41px;
    padding: 7px 10px 10px 10px;
  }

  .program-details-wrapper .border-for-google-app-playstore {
    width: 100%;
  }

  .program-details-wrapper .program-title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 35px;
  }

  .payment-summary-wrapper .promo-input-box .MuiFormControl-root {
    width: 100%;
  }

  .program-details-wrapper .program-tags {
    margin-top: 12px;
    margin-bottom: 4px;
  }

  .program-details-wrapper .price {
    font-size: 32px;
    margin-bottom: 3px;
    line-height: 38px;
  }

  .program-details-wrapper .program-extra-details {
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }

  .program-details-wrapper .program-actions {
    font-size: 12px;
    line-height: 14px;
    margin-top: 20px;
    button {
      margin: 5px;
    }
    .cart-text {
      font-size: 14px;
    }
  }

  .personal-info-wrapper .gender-radio .gender-form {
    padding: 0 0px;
  }

  .filter-programs {
    margin-left: 5%;
  }

  .cart-items-wrapper .cart-items-list .item-details {
    .item-price {
      font-size: 18px;
      line-height: 22px;
    }

    .item-review-message-user {
      font-size: 18px;
      line-height: 22px;
    }

    .item-verified-owner {
      font-size: 18px;
      line-height: 22px;
    }

    .review-date-display {
      font-size: 18px;
      line-height: 22px;
    }

    .item-title {
      font-size: 22px;
      line-height: 27px;
    }

    .item-title-review-name {
      font-size: 22px;
      line-height: 27px;
    }
  }
}

@media (max-width: 991px) {
  .programs-wrapper {
    height: auto;
  }

  .programs-wrapper .premiun-benifts-words {
    margin-bottom: 21px;
  }

  .programs-wrapper .community-image-section {
    margin-bottom: 10px;
  }

  .programs-wrapper .premium-benefits-section-for-profile {
    height: auto;
  }

  .programs-wrapper .distrubeted-access-section {
    display: block;
  }
}

@media (max-width: 899px) {
  .slick-prev {
    left: -4px !important;
  }

  .slick-next {
    right: -4px !important;
  }

  .full-access-text {
    width: 100%;
    margin-bottom: 22px;
  }

  .programs-wrapper {
    height: auto;
  }

  .programs-wrapper .profile-weight {
    position: relative;
    width: auto;
  }

  .programs-wrapper .border-for-plans {
    width: 100%;
  }

  .purchase-table-changes .MuiPaper-root {
    width: 100%;
  }
  .programs-wrapper .program-image {
    height: 417px;
    img {
      height: 417px;
    }
  }
}

@media (max-width: 768px) {
  .programs-wrapper .program-tags .tag span {
    // padding: 10px 8px 11px 9px;
    font-size: 10px;
  }
  .programs-wrapper {
    .program-title {
      font-size: 18px !important;
      line-height: normal;
    }
    .price-text-value {
      font-size: 18px;
    }
    .program-actions {
      .cart-text {
        font-size: 10px;
      }
      .cart-icon img {
        max-height: 15px !important;
        max-width: 20px !important;
      }
    }
  }
  .special-offer {
    display: block;
    .description {
      padding-right: 0;
    }
  }
  .new-button-design {
    padding: 0 !important;
    font-size: 12px !important;
    height: 18px !important;
  }
  .programs-wrapper {
    height: auto;
  }

  .purchase-table-changes .displaying-purpose {
    display: block;
  }

  .purchase-table-changes .MuiPaper-root {
    width: 100%;
  }

  .program-title,
  .program-description {
    font-weight: 550 !important;
    font-size: 20px !important;
    line-height: 25px !important;
  }

  .filter-programs {
    margin-left: 0%;
  }

  .cart-items-wrapper {
    .cart-items-count {
      font-weight: 350;
      font-size: 28px;
      line-height: 44px;
    }

    .cart-items-list {
      .item-details {
        .item-title {
          font-weight: 500;
          font-size: 16px;
          max-width: 100%;
          line-height: 22px;
        }

        .item-title-review-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
        }

        .item-price {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }

        .card-number.verify {
          width: 100% !important;
          text-align: left !important;
        }

        .item-review-message-user {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }

        .item-verified-owner {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }

        .review-date-display {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  .program-image-wrapper {
    align-items: center;
  }
  .programs-wrapper {
    .program-image {
      height: 300px;
      img {
        height: 300px;
      }
    }
    .program-image {
      height: auto;
      &.my-program {
        max-height: 400px;
        max-width: 400px;
        min-height: 338px;
        img {
          max-height: 100%;
          height: 100%;
        }
      }
    }
  }
}

.payment-actions {
  .social-media {
    justify-content: center;
  }
}

@media (max-width: 599px) {
  .rating-section {
    display: inline-flex;
  }
  .order-title {
    font-size: 20px;
  }
  .form-wrap form.guest-checkout {
    padding: 10px 20px;
  }
  .promo-input-box.guest-checkout {
    width: auto !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .form-wrap.checkout-guest {
    width: 90% !important;
  }
  .program-actions {
    display: inline-grid;
  }
  .programs-wrapper .rating-wrapper .program-rating {
    display: block;
  }
  .program-details-wrapper .program-details-image {
    display: inline-block;
  }
  .purchase-table-changes .displaying-purpose {
    display: flex;
  }
  .programs-wrapper .program-tags .tag span {
    padding: 10px 23px 10px 23px;
  }

  .banner-title-app {
    font-size: 37px;
    margin-bottom: 37px;
    margin-top: 12px;
  }
  .programs-wrapper .program-image {
    min-height: 220px;
  }
  .programs-wrapper .program-title {
    min-height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;

    line-height: 20px;
  }
  .programs-wrapper {
    height: auto;
  }

  .program-details-wrapper {
    .program-details-image span {
      width: auto !important;
    }
    .program-description-wrapper .desc {
      text-align: left;
    }
    .program-details-image {
      margin-right: 0px;
    }
    .view-all-text {
      text-align: center;
    }

    .program-rating {
      display: block;
    }

    .section-for-showing-review {
      padding: 13px;
    }
  }

  .cart-items-wrapper .cart-items-list .user-name-for-review {
    display: block;
  }

  .cart-items-wrapper .cart-items-list .item-image-review img {
    padding: 10px 0px 10px 0px !important;
  }

  .cart-items-wrapper .cart-items-list .item-details .item-title-review-name {
    margin-left: 0px;
    padding-top: 6px;
    padding-bottom: 10px;
  }

  .cart-items-wrapper .cart-items-list .review-item {
    display: block;
  }

  .program-tags {
    justify-content: center !important;
  }

  .payment-summary-wrapper {
    margin: 10px;
  }

  // .cart-items-wrapper {
  //   margin: 10px;
  // }

  .promo-code-wrapper {
    justify-content: center;
    align-items: center;
    display: block !important;

    .promo-action {
      padding-left: 10px;
    }
  }

  .billing-summary-wrapper {
    .summary-title {
      text-align: left;
    }

    .summary-title-billing {
      text-align: left;
    }
  }

  .payment-summary-wrapper {
    .title {
      text-align: left;
    }
  }
  .programs-wrapper {
    .program-image.my-program {
      max-height: 400px;
      width: 400px;
      img {
        // height: 100%;
        // width: auto;
        max-width: 400px;
      }
    }
  }
}

@media (max-width: 491px) {
  .programs-wrapper .program-tags .tag span {
    padding: 10px 8px 11px 9px;
  }

  .banner-title-app {
    font-size: 28px;
  }

  .programs-wrapper {
    height: auto;
  }

  .programs-wrapper .Subscribe-button-subscribe-now {
    margin-top: 33px;

    padding: 9px 19px 11px 19px;
    height: 40px;
    font-size: 11px;
  }
  .bundle-programs-wrapper .item-wrapper {
    display: block;
    .item-title,
    .item-price {
      font-size: 20px;
    }
  }
  .programs-wrapper .program-image {
    height: 250px;
    img {
      height: 250px;
    }
  }
  .programs-wrapper {
    .program-image.my-program {
      max-height: 350px;
      width: 350px;
      img {
        max-width: 350px;
        height: auto;
      }
    }
  }
}

@media (max-width: 430px) {
  .programs-wrapper {
    height: auto;
  }
  .form-wrap.checkout-guest {
    width: 95% !important;
  }
  .programs-wrapper {
    .program-title {
      font-size: 15px !important;
      line-height: normal;
    }
    .price-text-value {
      font-size: 13px;
    }
    .program-actions {
      .cart-text {
        font-size: 10px;
      }
      .cart-icon img {
        max-height: 15px !important;
        max-width: 20px !important;
      }
    }
    .checkout-wrapper {
      text-align: center;
    }
    .checkout-btn-cart {
      margin-top: 15px;
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  .personal-info-wrapper .profile-photo-wrapper .remove-button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .order-title {
    font-size: 20px;
  }
  .order-title.billing {
    font-size: 24px;
  }
  .form-wrap form.guest-checkout {
    padding: 10px 20px;
  }
  .promo-input-box.guest-checkout {
    width: auto !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .program-tags {
    justify-content: center !important;
  }

  .divider {
    display: none;
  }

  .program-rating {
    justify-content: center;
  }

  .payment-summary-wrapper {
    height: auto;
    min-height: auto;
  }
  .programs-wrapper .program-image {
    min-height: 180px;
  }
  .programs-wrapper .save-card-wrapper {
    width: 100% !important;
    padding-left: 0;

    .add-card-btn.cancel {
      width: 100%;
      margin-left: 0;
      margin-top: 5px;
    }

    .add-card-btn.save {
      width: 100%;
    }
  }

  .payment-summary-wrapper {
    .billing-summary-wrapper {
      .summary-table.card-checkout {
        padding-top: 0;
      }
    }
  }

  .cart-items-wrapper {
    .cart-items-list {
      .item-details {
        .position-relative,
        .card-name,
        .card-expire-wrapper {
          width: 100% !important;
          input {
            &::placeholder {
              opacity: 0.2;
            }
          }
        }
      }
    }

    .cart-items-count {
      text-align: left;
      font-weight: 300;
      font-size: 24px;
      line-height: 40px;
    }

    .cart-items-list {
      .item-details {
        width: 100% !important;

        .item-title {
          font-weight: 400;
          text-align: left;
          font-size: 14px;
          line-height: 18px;
        }

        .item-price {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: left;
          padding-top: 5px;
        }

        .item-review-message-user {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: left;
          padding-top: 5px;
        }

        .item-verified-owner {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          padding-top: 5px;
        }

        .review-date-display {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          padding-top: 5px;
        }
      }
    }
  }
  .card-name {
    input {
      & ::-webkit-input-placeholder {
        opacity: 0.2 !important;
      }
    }
  }
  .programs-wrapper {
    .checkout-btn {
      width: 150px;
      height: 50px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
    .program-image {
      height: 180px;
      img {
        height: 180px;
      }
    }
    .program-image.my-program {
      height: auto;
      width: auto;
      min-height: auto;
      img {
        height: auto;
      }
    }
  }
}

@media (max-width: 425px) {
  .special-offer {
    display: block;
  }

  .programs-wrapper {
    height: auto;
    .checkout-btn-cart {
      padding: 8px 30px;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      height: auto;
    }
  }
  .form-wrap {
    .item-wrapper {
      &.guest-checkout {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        margin-bottom: 10px;
      }
      .radio-title {
        font-size: 14px;
      }
    }
  }
  .program-details-wrapper .program-actions .MuiButton-root {
    width: 100%;
    height: auto;
  }
  .program-details-wrapper .program-details-image {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .programs-wrapper {
    .program-image {
      height: 180px;
      img {
        height: 180px;
      }
    }
    .program-image.my-program {
      height: auto;
      width: auto;
      min-height: auto;
      img {
        height: auto;
      }
    }
  }
}
@media (max-width: 374px) {
  .programs-wrapper .program-image {
    height: 150px;
    img {
      height: 150px;
    }
  }
  .form-wrap {
    &.checkout-form-wrap {
      .checkout-buttons {
        width: 80%;
        font-size: 12px;
      }
    }
  }
  .paypal-button-title {
    font-size: 12px;
  }
  .paypal-button .paypal-logo {
    font-size: 14px;
  }
  .my-program-tabs {
    font-size: 12px !important;
  }
}
